import Providers from "./context/Providers";
import App from "./App";

export default function AppWrapper() {
  return (
    <Providers>
      <App />
    </Providers>
  );
}
