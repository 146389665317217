import { BrowserRouter } from "react-router-dom";
import { useRoutes } from "./hooks/useRoutes";
import "./App.css";

function App() {
  const routes = useRoutes();

  return <BrowserRouter>{routes}</BrowserRouter>;
}

export default App;
