import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import AppWrapper from "./AppWrapper";
import "./utils/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);

(() => {
  ReactGA.initialize("G-R24ZDC2BXR");
})();
