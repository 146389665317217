import s from './Loading.module.scss';

//textPosition: left | bottom

export default function Loading({ children, isSmall, textPosition = 'bottom' }: any) {
  return (
    <div className={`${s.loader} ${isSmall && s.isSmall} ${s[textPosition]}`}>
      <div className={s.balls}>
        <div className={s.ball} />
        <div className={s.ball2} />
        <div className={s.ball3} />
      </div>
      <span className={s.customtext}>{children}</span>
    </div>
  );
}