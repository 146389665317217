import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      // Thumbnail
      thumbnail_title: "Who we are",
      thumbnail_subtitle1:
        "Flavy  - is  a reliable partner in the world of fast-paced casino games! We specialize in developing and providing unique solutions for gambling games, including popular games like Crash, Towers, Mines, Plinko, and Dice. Our goal is to make your casino even more exciting and profitable.",
      thumbnail_subtitle2:
        "What sets us apart? We offer not just services but a long-term partnership, including 24/7 support, flexible terms, and competitive prices. Our team consists of true professionals, driven by energy and ready to help you succeed in the world of gambling entertainment.",

      // StatsNumbers
      statsnumbers1_title: "Innovations of our standard",
      statsnumbers1_subtitle:
        "At Flavy, innovations are not just words. We constantly seek new ways to enhance our solutions, ensuring that your casino always stays ahead of the competition.",
      statsnumbers2_title: "24/7 Support",
      statsnumbers2_subtitle:
        "Our specialists are available to you around the clock. We are ready to address any issues and answer your questions at any time of day or night.",

      statsnumbersgames1_title: "Multichannel",
      statsnumbersgames1_subtitle:
        "The game is available on various platforms: web version, mobile devices, and tablets.",
      statsnumbersgames3_title: "Exciting Gameplay",
      statsnumbersgames3_subtitle:
        "Our developers have carefully thought out every detail to provide a highly engaging gameplay experience. The player's experience will be filled with the excitement and atmosphere of gambling establishments.",

      statsnumberscrash2_title: "Automatic Cashout",
      statsnumberscrash2_subtitle:
        "We provide users with the ability to set their own multipliers for fund withdrawal.",
      statsnumberscrash4_title: "Security and Transparency",
      statsnumberscrash4_subtitle:
        "We value security and take every measure to protect data. Our Crash game utilizes advanced encryption and security technologies.",

      statsnumbersmines2_title: "Risk Control",
      statsnumbersmines2_subtitle:
        "Mines offers players the option to choose the level of difficulty. The higher the difficulty, the greater the risk.",
      statsnumbersmines4_title: "Security and Transparency",
      statsnumbersmines4_subtitle:
        "We value security and take every measure to protect data. Our Mines game utilizes advanced encryption and security technologies.",

      statsnumberstowers2_title: "Risk Control",
      statsnumberstowers2_subtitle:
        "Towers offers players the option to choose the level of difficulty. The higher the difficulty, the greater the risk.",
      statsnumberstowers4_title: "Security and Transparency",
      statsnumberstowers4_subtitle:
        "We value security and take every measure to protect data. Our Towers game utilizes advanced encryption and security technologies.",

      statsnumbersplinko2_title: "Risk Control",
      statsnumbersplinko2_subtitle:
        "In Plinko, players have the option to choose the number of slots to bet on, adding diversity to the game. More slots mean more chances for significant winnings but also higher risk.",
      statsnumbersplinko4_title: "Security and Transparency",
      statsnumbersplinko4_subtitle:
        "We value security and take every measure to protect data. Our Plinko game utilizes advanced encryption and security technologies.",

      statsnumbersdice2_title: "Risk Control",
      statsnumbersdice2_subtitle:
        "Dice offers a variety of betting options and opportunities. Players can choose different strategies and risk levels.",
      statsnumbersdice4_title: "Security and Transparency",
      statsnumbersdice4_subtitle:
        "We value security and take every measure to protect data. Our Dice game utilizes advanced encryption and security technologies.",

      // Stats
      stats_slider1_title: "Fast games available at the moment",
      stats_slider2_title: "True Randomness:",
      stats_slider2_subtitle:
        "We pay special attention to ensuring guaranteed randomness in our games. To achieve this, we employ advanced random number generation technologies.",
      stats_slider3_title: "In Our fast games:",
      stats_slider3_subtitle_left: `RTP(player payout percentage) -
        Default Maximum Win -
        Default Min/Max Bet ($)`,
      stats_slider3_subtitle_right: `Up to 97% -
        Configurable -
        Configurable`,

      //DemoThumbnail
      demothumbnail_title: "Try it now",
      demothumbnail_mines_subtitle:
        "Mines - a game where the player's task is to uncover as many precious gems in a row as possible while avoiding unexpected explosions. For each correct choice when players find a gem, their winnings increase, but as the multiplier grows, so do the risks of hitting a mine. The game offers them a choice: play it safe and take the winnings or take a risk and go for the grand prize.",
      demothumbnail_plinko_subtitle:
        "Plinko is an exciting and fast-paced gambling game where winnings are determined by where the sliding ball falls. The size of the player's winnings depends on which slot the ball lands in.",
      demothumbnail_towers_subtitle:
        "Towers is a thrilling game mode where the player must make the right moves, avoiding bombs to advance to the next level. With each successful level, their winnings increase. In Towers, every move can lead to profit growth or end in a loss for the player.",
      demothumbnail_dice_subtitle:
        "Dice is a game of chance and strategy where the player rolls the dice and bets on the outcome. The higher the risk, the greater the potential reward. Players can choose different strategies and risk levels.",
      demothumbnail_crash_subtitle:
        "Crash is a game based on betting on a rapidly increasing multiplier that can crash at any moment. The player's task is to exit the game in time before it'crashes.' The longer the player waits, the higher their potential winnings, but as the multiplier increases, so does the risk of losing the entire bet.",

      //ContactForm
      contactform_title: "Contact Us",
      contactform_name: "Name*",
      contactform_company: "Company*",
      contactform_email: "E-mail address*",
      contactform_message: "Your Message*",

      //Header
      header_home: "Home",
      header_products: "Products",
      header_contact: "Contact us",
      header_language: "Language:",

      //Privacy Policy
      privacy_title: "Privacy Policy",
      privacy_subtitle:
        "Welcome to Flavy Softs, your destination for cutting-edge digital experiences, operated by FLAVY LLC. At FLAVY SOFTS, we prioritize your privacy, and this SEO-optimized Privacy Policy outlines how we collect, utilize, and safeguard your information when you engage with our services on the flavysofts.com website.",
      privacy_item1_title: "1. Data Collection:",
      privacy_item1_subtitle:
        "Discover a seamless user experience by providing us with certain personally identifiable information such as your name, address, email, phone number, and account credentials. Additionally, we enhance your interaction by gathering technical details, internet communications records, and location data.",
      privacy_item2_title: "2. Lawful Basis for Processing:",
      privacy_item2_subtitle:
        "Rest assured that we process personal data in strict compliance with your explicit consent or when sourced from publicly available channels, adhering to legal standards.",
      privacy_item3_title: "3. Confidentiality:",
      privacy_item3_subtitle:
        "Your personal data is treated as confidential, protected by robust legal measures. Our committed team, including employees and authorized personnel, upholds non-disclosure agreements and understands the gravity of violating data processing norms.",
      privacy_item4_title: "4. Data Usage:",
      privacy_item4_subtitle:
        "Immerse yourself in our services as we process your data to provide and maintain top-tier experiences, comply with legal obligations, process transactions, offer responsive customer support, enhance services, and troubleshoot technical issues.",
      privacy_item5_title: "5. Sharing Your Data:",
      privacy_item5_subtitle:
        "Experience seamless gaming through trusted third-party providers delivering essential services. This includes software and game providers, banking and payment services, identity verification, fraud prevention, and risk management. Be assured that, in compliance with legal obligations, information may be disclosed to government authorities.",
      privacy_item6_title: "6. Data Storage and Protection:",
      privacy_item6_subtitle:
        "Your data is securely stored and protected by stringent contractual controls and encryption measures. Feel confident that we retain your data as long as you're an active customer and as required by legal obligations.",
      privacy_item7_title: "7. Your Rights:",
      privacy_item7_subtitle:
        "Embrace your fundamental rights as a data subject – withdraw consent, access and correct your data, request erasure, restrict processing, and enjoy the right to object to processing.",
      privacy_item8_title: "8. Cookie Policy:",
      privacy_item8_subtitle:
        "Indulge in a personalized online experience as we use cookies to heighten user satisfaction, streamline site functionality, and collect data for fraud prevention. Manage your preferences by adjusting browser settings while remembering that blocking cookies may impact website functionality.",
      privacy_item9_title: "9. Changes to this Policy:",
      privacy_item9_subtitle:
        "Stay updated on the evolution of your digital journey. We reserve the right to enhance this Privacy Policy, with substantial changes communicated directly. Your continued use of our services post-updates implies acceptance of the revised policy.",
      privacy_footer:
        "For inquiries about your personal data, our Data Protection Officer is available at DataProtectionOffice@flavysofts.com.",
      privacy_date: "Last Updated: ",

      // buttons
      contact_button: "Contact us",
      view_button: "View demo",
      return_button: "Return to home page",
      fullscreen_button: "Full Screen",
    },
  },
  ru: {
    translation: {
      // Thumbnail
      thumbnail_title: "О Нас",
      thumbnail_subtitle1:
        "Компания Flavy — надежный партнер в мире быстрых игр для казино! Мы специализируемся на разработке и предоставлении уникальных решений для азартных игр, включая популярные игры, такие как Краш, Башни, Мины, Плинко и Кости. Наша цель - сделать ваше казино ещё более увлекательным и прибыльным.",
      thumbnail_subtitle2:
        "Что делает нас особенными? Мы предоставляем не просто услуги, а долгосрочное партнерство, включая 24-часовую поддержку, гибкие условия и доступные цены. Наша команда - это настоящие профессионалы, заряженные энергией и готовые помочь вам достичь успеха в мире азартных развлечений.",

      // StatsNumbers
      statsnumbers1_title: "Инновации нашего стандарта",
      statsnumbers1_subtitle:
        "В Flavy, инновации - это не просто слова. Мы постоянно ищем новые способы улучшить наши решения, чтобы ваше казино всегда оставалось впереди конкурентов.",
      statsnumbers2_title: "24/7 Поддержка",
      statsnumbers2_subtitle:
        "Наши специалисты доступны для вас круглосуточно. Мы готовы решать любые задачи и ответить на ваши вопросы в любое время дня и ночи.",

      statsnumbersgames1_title: "Многоканальность",
      statsnumbersgames1_subtitle:
        "Игра доступна на различных платформах: веб-версии, мобильных устройствах и планшетах.",
      statsnumbersgames3_title: "Увлекательный Геймплей",
      statsnumbersgames3_subtitle:
        "Наши разработчики тщательно продумали каждую деталь, чтобы обеспечить максимально увлекательный геймплей.Опыт игрока будет пропитан азартом и атмосферой азартных игорных заведений.",

      statsnumberscrash2_title: "Автоматический Кешаут",
      statsnumberscrash2_subtitle:
        "Мы предоставляем возможность клиентам устанавливать собственные коэффициенты, при которых осуществляется вывод средств.",
      statsnumberscrash4_title: "Безопасность и Прозрачность",
      statsnumberscrash4_subtitle:
        "Мы ценим безопасность и делаем все возможное, чтобы обеспечить защиту данных. Наша игра Crash использует передовые технологии шифрования и безопасности.",

      statsnumbersmines2_title: "Контроль риска",
      statsnumbersmines2_subtitle:
        "Mines предоставляет игроку возможность выбора уровня сложности. Чем выше сложность, тем больше риск.",
      statsnumbersmines4_title: "Безопасность и Прозрачность",
      statsnumbersmines4_subtitle:
        "Мы ценим безопасность и делаем все возможное, чтобы обеспечить защиту данных. Наша игра Mines использует передовые технологии шифрования и безопасности.",

      statsnumberstowers2_title: "Контроль риска",
      statsnumberstowers2_subtitle:
        "Towers предоставляет игроку возможность выбора уровня сложности. Чем выше сложность, тем больше риск.",
      statsnumberstowers4_title: "Безопасность и Прозрачность",
      statsnumberstowers4_subtitle:
        "Мы ценим безопасность и делаем все возможное, чтобы обеспечить защиту данных. Наша игра Towers использует передовые технологии шифрования и безопасности.",

      statsnumbersplinko2_title: "Контроль риска",
      statsnumbersplinko2_subtitle:
        "В Plinko игрок имеет возможность выбирать разное количество ячеек для ставки, что придает игре максимальное разнообразие. Больше ячеек означает больше шансов на крупные выигрыши, но также и больший риск.",
      statsnumbersplinko4_title: "Безопасность и Прозрачность",
      statsnumbersplinko4_subtitle:
        "Мы ценим безопасность и делаем все возможное, чтобы обеспечить защиту данных. Наша игра Plinko использует передовые технологии шифрования и безопасности.",

      statsnumbersdice2_title: "Контроль риска",
      statsnumbersdice2_subtitle:
        "Dice предлагает разнообразие ставок и возможностей. Игрок может выбрать разные стратегии и уровни риска.",
      statsnumbersdice4_title: "Безопасность и Прозрачность",
      statsnumbersdice4_subtitle:
        "Мы ценим безопасность и делаем все возможное, чтобы обеспечить защиту данных. Наша игра Dice использует передовые технологии шифрования и безопасности.",

      // Stats
      stats_slider1_title: "Быстрые игры, которые доступны на данный момент",
      stats_slider2_title: "Случайность Исхода:",
      stats_slider2_subtitle:
        "Мы уделяем особое внимание гарантированной случайности в наших играх. Для этого мы используем передовые технологии генерации случайных чисел. ",
      stats_slider3_title: "В Наших Быстрых играх доступны:",
      stats_slider3_subtitle_left: `RTP(процент выплат игрокам) -
      Максимальный выигрыш -
      Мин./макс. ставка ($)`,
      stats_slider3_subtitle_right: `до 97% -
      Настраиваемый -
      Настраивамая`,

      //DemoThumbnail
      demothumbnail_title: "Попруйте прямо сейчас",
      demothumbnail_mines_subtitle:
        "Mines - это игра, где задача игрока - раскрывать подряд как можно больше драгоценных камней, избегая неожиданных взрывов. За каждый правильный выбор, когда он находит драгоценность, его выигрыш увеличивается, но с ростом множителя увеличиваются и риски попасть на мину. Игра предоставляет ему выбор: играть на безопасность и забрать выигрыш или рискнуть и пойти за главным призом.",
      demothumbnail_plinko_subtitle:
        "Plinko - это увлекательная и быстрая азартная игра, где выигрыши определяются тем, куда падает скользящий шар.",
      demothumbnail_towers_subtitle:
        "Towers - это захватывающий режим, где игроку предстоит выбирать правильные ходы, избегая бомб, чтобы продвигаться на следующий уровень. С каждым успешным уровнем его выигрыш увеличивается. В Towers каждый ход может привести к росту прибыли так и закончиться проигрышем для игрока.",
      demothumbnail_dice_subtitle:
        "Dice - это игра азарта и стратегии, где игрок бросает кубик и ставит на исход.  Чем выше риск, тем больше вознаграждение. Игрок определяет свою стратегию,  ощущая азарт на себе.",
      demothumbnail_crash_subtitle:
        "Crash - это игра, основанная на ставках на множитель, который стремительно растет. Задача игрока - вовремя выйти из игры, прежде чем она 'падет'. Чем дольше игрок ждет, тем выше может быть его выигрыш. Однако с ростом множителя увеличивается и риск потери всей ставки.",

      //ContactForm
      contactform_title: "Свяжитесь с Нами",
      contactform_name: "Name*",
      contactform_company: "Company*",
      contactform_email: "E-mail address*",
      contactform_message: "Your Message*",

      //Header
      header_home: "Главная",
      header_products: "Продукция",
      header_contact: "Свяжитесь с нами",
      header_language: "Язык:",

      //Privacy Policy
      privacy_title: "Политика Конфиденциальности",
      privacy_subtitle:
        "Добро пожаловать в Flavy Softs, ваш пункт назначения для передовых цифровых впечатлений, управляемый FLAVY LLC. В FLAVY SOFTS мы отдаем приоритет вашей конфиденциальности, и эта оптимизированная для SEO Политика конфиденциальности описывает, как мы собираем, используем и обеспечиваем защиту вашей информации, когда вы взаимодействуете с нашими услугами на веб-сайте flavysofts.com.",
      privacy_item1_title: "1. Сбор данных:",
      privacy_item1_subtitle:
        "Погрузитесь в бесперебойный пользовательский опыт, предоставив нам определенную личную информацию, такую как ваше имя, адрес, электронная почта, номер телефона и учетные данные. Кроме того, мы улучшаем ваше взаимодействие, собирая технические детали, записи интернет-коммуникаций и данные о местоположении.",
      privacy_item2_title: "2. Правовая основа обработки:",
      privacy_item2_subtitle:
        "Будьте уверены, что мы обрабатываем личные данные в строгом соответствии с вашим явным согласием или при получении из общедоступных источников, соблюдая правовые стандарты.",
      privacy_item3_title: "3. Конфиденциальность:",
      privacy_item3_subtitle:
        "Ваши личные данные считаются конфиденциальными, защищенными надежными юридическими мерами. Наш преданный коллектив, включая сотрудников и уполномоченных лиц, соблюдает соглашения о неразглашении и понимает серьезность нарушения норм обработки данных.",
      privacy_item4_title: "4. Использование данных:",
      privacy_item4_subtitle:
        "Погрузитесь в наши услуги, поскольку мы обрабатываем ваши данные для предоставления и поддержания высококачественных впечатлений, соблюдения юридических обязательств, обработки транзакций, предоставления отзывчивой поддержки, улучшения услуг и устранения технических проблем.",
      privacy_item5_title: "5. Обмен вашими данными:",
      privacy_item5_subtitle:
        "Почувствуйте плавность игрового процесса через доверенных поставщиков услуг. Это включает поставщиков программного обеспечения и игр, банковские и платежные услуги, верификацию личности, предотвращение мошенничества и управление рисками. Будьте уверены, что в соответствии с юридическими обязательствами информация может быть раскрыта правительственным органам.",
      privacy_item6_title: "6. Хранение и защита данных:",
      privacy_item6_subtitle:
        "Ваши данные надежно хранятся и защищаются строгими контрактными контролями и мерами шифрования. Уверены, что мы сохраняем ваши данные на протяжении вашего активного использования услуг и в соответствии с юридическими обязательствами.",
      privacy_item7_title: "7. Ваши права:",
      privacy_item7_subtitle:
        "Примите свои основные права как субъект данных - отзывайте согласие, получайте доступ и исправляйте свои данные, запрашивайте стирание, ограничивайте обработку и наслаждайтесь правом возражения против обработки.",
      privacy_item8_title: "8. Политика файлов Cookie:",
      privacy_item8_subtitle:
        "Наслаждайтесь персонализированным онлайн-опытом, используя файлы cookie для повышения удовлетворения пользователя, оптимизации функциональности сайта и сбора данных для предотвращения мошенничества. Управляйте своими предпочтениями, настраивая параметры браузера, помня при этом, что блокировка файлов cookie может повлиять на функциональность веб-сайта.",
      privacy_item9_title: "9. Изменения в этой политике:",
      privacy_item9_subtitle:
        "Оставайтесь в курсе эволюции вашего цифрового путешествия. Мы оставляем за собой право улучшать эту Политику конфиденциальности, с существенными изменениями, которые будут предоставлены вам непосредственно. Ваше продолжение использования наших услуг после обновлений подразумевает согласие с пересмотренной политикой.",
      privacy_footer:
        "По всем вопросам о вашей личной информации обращайтесь к нашему ответственному за защиту данных по адресу DataProtectionOffice@flavysofts.com.",
      privacy_date: "Последнее обновление: ",

      // buttons
      contact_button: "Cвязаться с нами",
      view_button: "View demo",
      return_button: "Return to home page",
      fullscreen_button: "Полный Экран",
    },
  },
  es: {
    translation: {
      // Thumbnail
      thumbnail_title: "Sobre Nosotros",
      thumbnail_subtitle1:
        "La empresa Flavy es un socio confiable en el mundo de los juegos de casino rápidos. Nos especializamos en el desarrollo y provisión de soluciones únicas para juegos de azar, incluyendo juegos populares como Crash, Torres, Minas, Plinko y Dados. Nuestro objetivo es hacer que su casino sea aún más emocionante y rentable.",
      thumbnail_subtitle2:
        "¿Qué nos hace especiales? Ofrecemos no solo servicios, sino una asociación a largo plazo que incluye soporte las 24 horas, condiciones flexibles y precios accesibles. Nuestro equipo está formado por verdaderos profesionales, llenos de energía y listos para ayudarlo a alcanzar el éxito en el mundo del entretenimiento de azar.",

      // StatsNumbers
      statsnumbers1_title: "Innovaciones de nuestro estándar",
      statsnumbers1_subtitle:
        "En Flavy, las innovaciones no son solo palabras. Buscamos constantemente nuevas formas de mejorar nuestras soluciones, asegurando que su casino siempre esté por delante de la competencia.",
      statsnumbers2_title: "Soporte 24/7",
      statsnumbers2_subtitle:
        "Nuestros especialistas están disponibles para usted las 24 horas del día. Estamos listos para abordar cualquier problema y responder a sus preguntas en cualquier momento del día o de la noche.",

      statsnumbersgames1_title: "Multicanalidad",
      statsnumbersgames1_subtitle:
        "El juego está disponible en varias plataformas: versión web, dispositivos móviles y tabletas.",
      statsnumbersgames3_title: "Jugabilidad Emocionante",
      statsnumbersgames3_subtitle:
        "Nuestros desarrolladores han cuidado cada detalle para brindar una experiencia de juego altamente emocionante. La experiencia del jugador estará llena de emoción y la atmósfera de los establecimientos de juego de azar.",

      statsnumberscrash2_title: "Retiro Automático",
      statsnumberscrash2_subtitle:
        "Proporcionamos a los clientes la capacidad de establecer sus propios multiplicadores para el retiro de fondos.",
      statsnumberscrash4_title: "Seguridad y Transparencia",
      statsnumberscrash4_subtitle:
        "Valoramos la seguridad y tomamos todas las medidas para proteger los datos. Nuestro juego Crash utiliza tecnologías avanzadas de cifrado y seguridad.",

      statsnumbersmines2_title: "Control de Riesgos",
      statsnumbersmines2_subtitle:
        "Mines ofrece a los jugadores la opción de elegir el nivel de dificultad. Cuanto mayor sea la dificultad, mayor será el riesgo.",
      statsnumbersmines4_title: "Seguridad y Transparencia",
      statsnumbersmines4_subtitle:
        "Valoramos la seguridad y tomamos todas las medidas para proteger los datos. Nuestro juego Mines utiliza tecnologías avanzadas de cifrado y seguridad.",

      statsnumberstowers2_title: "Control de Riesgos",
      statsnumberstowers2_subtitle:
        "Towers ofrece a los jugadores la opción de elegir el nivel de dificultad. Cuanto mayor sea la dificultad, mayor será el riesgo.",
      statsnumberstowers4_title: "Seguridad y Transparencia",
      statsnumberstowers4_subtitle:
        "Valoramos la seguridad y tomamos todas las medidas para proteger los datos. Nuestro juego Towers utiliza tecnologías avanzadas de cifrado y seguridad.",

      statsnumbersplinko2_title: "Control de Riesgos",
      statsnumbersplinko2_subtitle:
        "En Plinko, los jugadores tienen la opción de elegir la cantidad de ranuras en las que apostar, lo que agrega diversidad al juego. Más ranuras significan más oportunidades para ganancias significativas, pero también un mayor riesgo.",
      statsnumbersplinko4_title: "Seguridad y Transparencia",
      statsnumbersplinko4_subtitle:
        "Valoramos la seguridad y tomamos todas las medidas para proteger los datos. Nuestro juego Plinko utiliza tecnologías avanzadas de cifrado y seguridad.",

      statsnumbersdice2_title: "Control de Riesgos",
      statsnumbersdice2_subtitle:
        "Dice ofrece una variedad de opciones y oportunidades de apuestas. Los jugadores pueden elegir diferentes estrategias y niveles de riesgo.",
      statsnumbersdice4_title: "Seguridad y Transparencia",
      statsnumbersdice4_subtitle:
        "Valoramos la seguridad y tomamos todas las medidas para proteger los datos. Nuestro juego Dice utiliza tecnologías avanzadas de cifrado y seguridad.",

      // Stats
      stats_slider1_title: "Juegos rápidos disponibles en este momento",
      stats_slider2_title: "Verdadera aleatoriedad:",
      stats_slider2_subtitle:
        "Prestamos especial atención para garantizar la aleatoriedad en nuestros juegos. lograrlo, utilizamos tecnologías avanzadas de generación de números aleatorios.",
      stats_slider3_title: "En Nuestros juegos rápidos ofrecemos:",
      stats_slider3_subtitle_left: `RTP(retorno al jugador) -
      Ganancia máxima -
      Apuesta máx./mín. ($)`,
      stats_slider3_subtitle_right: `hasta un 97% -
      Configurable -
      Configurable`,

      //DemoThumbnail
      demothumbnail_title: "Pruébalo ahora",
      demothumbnail_mines_subtitle:
        "Mines - es un juego en el que el objetivo del jugador es descubrir tantas gemas preciosas seguidas como sea posible, evitando explosiones inesperadas. Por cada elección correcta cuando encuentra una gema, sus ganancias aumentan, pero a medida que el multiplicador crece, también aumentan los riesgos de encontrar una mina. El juego le ofrece una elección: jugar de manera segura y llevarse las ganancias o arriesgarse e ir tras el gran premio.",
      demothumbnail_plinko_subtitle:
        "Plinko - es un emocionante y rápido juego de azar donde las ganancias se determinan por dónde cae la bola deslizante.",
      demothumbnail_towers_subtitle:
        "Towers - es un modo emocionante en el que el jugador debe tomar decisiones correctas, evitando bombas para avanzar al siguiente nivel. Con cada nivel exitoso, sus ganancias aumentan. En Towers, cada movimiento puede llevar al crecimiento de las ganancias o terminar en una pérdida para el jugador.",
      demothumbnail_dice_subtitle:
        "Dice - es un juego de azar y estrategia en el que el jugador lanza el dado y apuesta por el resultado. Cuanto mayor sea el riesgo, mayores serán las recompensas potenciales. Los jugadores pueden elegir diferentes estrategias y niveles de riesgo.",
      demothumbnail_crash_subtitle:
        "Crash - es un juego basado en apuestas en un multiplicador que aumenta rápidamente. La tarea del jugador es salir del juego a tiempo antes de que 'se estrelle'. Cuanto más tiempo espere el jugador, mayores serán sus posibles ganancias, pero a medida que aumenta el multiplicador, también aumenta el riesgo de perder toda la apuesta.",

      //ContactForm
      contactform_title: "Contáctenos",
      contactform_name: "Name*",
      contactform_company: "Company*",
      contactform_email: "E-mail address*",
      contactform_message: "Your Message*",

      //Header
      header_home: "Página Principal",
      header_products: "Productos",
      header_contact: "Contáctenos",
      header_language: "Lengua:",

      //Privacy Policy
      privacy_title: "POLÍTICA DE PRIVACIDAD",
      privacy_subtitle:
        "Bienvenido a Flavy Softs, tu destino para experiencias digitales de vanguardia, operado por FLAVY LLC. En FLAVY SOFTS, priorizamos tu privacidad, y esta Política de Privacidad optimizada para SEO describe cómo recopilamos, utilizamos y protegemos tu información cuando interactúas con nuestros servicios en el sitio web flavysofts.com.",
      privacy_item1_title: "1. Recopilación de Datos:",
      privacy_item1_subtitle:
        "Descubre una experiencia de usuario fluida al proporcionarnos cierta información personal identificable, como tu nombre, dirección, correo electrónico, número de teléfono y credenciales de cuenta. Además, mejoramos tu interacción recopilando detalles técnicos, registros de comunicaciones en Internet y datos de ubicación.",
      privacy_item2_title: "2. Base Legal para el Procesamiento:",
      privacy_item2_subtitle:
        "Ten la seguridad de que procesamos datos personales en estricto cumplimiento con tu consentimiento explícito o cuando se obtienen de fuentes públicas, cumpliendo con estándares legales.",
      privacy_item3_title: "3. Confidencialidad:",
      privacy_item3_subtitle:
        "Tu información personal se trata como confidencial y está protegida por medidas legales sólidas. Nuestro equipo comprometido, que incluye empleados y personal autorizado, cumple con acuerdos de no divulgación y comprende la gravedad de violar las normas de procesamiento de datos.",
      privacy_item4_title: "4. Uso de Datos:",
      privacy_item4_subtitle:
        "Sumérgete en nuestros servicios mientras procesamos tus datos para proporcionar y mantener experiencias de alta calidad, cumplir con obligaciones legales, procesar transacciones, ofrecer soporte al cliente receptivo, mejorar servicios y solucionar problemas técnicos.",
      privacy_item5_title: "5. Compartir Tus Datos:",
      privacy_item5_subtitle:
        "Experimenta un juego sin problemas a través de proveedores de confianza que ofrecen servicios esenciales. Esto incluye proveedores de software y juegos, servicios bancarios y de pago, verificación de identidad, prevención de fraudes y gestión de riesgos. Ten la seguridad de que, cumpliendo con obligaciones legales, la información puede ser revelada a las autoridades gubernamentales.",
      privacy_item6_title: "6. Almacenamiento y Protección de Datos:",
      privacy_item6_subtitle:
        "Tus datos se almacenan de forma segura y se protegen con controles contractuales estrictos y medidas de cifrado. Siéntete seguro de que conservamos tus datos mientras seas un cliente activo y según lo exijan las obligaciones legales.",
      privacy_item7_title: "7. Tus Derechos:",
      privacy_item7_subtitle:
        "Abraza tus derechos fundamentales como sujeto de datos: retirar el consentimiento, acceder y corregir tus datos, solicitar la eliminación, restringir el procesamiento y disfrutar del derecho a oponerte al procesamiento.",
      privacy_item8_title: "8. Política de Cookie:",
      privacy_item8_subtitle:
        "Disfruta de una experiencia en línea personalizada, ya que utilizamos cookies para aumentar la satisfacción del usuario, optimizar la funcionalidad del sitio y recopilar datos para prevenir fraudes. Gestiona tus preferencias ajustando la configuración del navegador, aunque recuerda que bloquear las cookies puede afectar la funcionalidad del sitio web.",
      privacy_item9_title: "9. Cambios en esta Política:",
      privacy_item9_subtitle:
        "Mantente actualizado sobre la evolución de tu viaje digital. Nos reservamos el derecho de mejorar esta Política de Privacidad, con cambios sustanciales comunicados directamente. Tu uso continuado de nuestros servicios después de las actualizaciones implica la aceptación de la política revisada.",
      privacy_footer:
        "Para consultas sobre tus datos personales, nuestro Oficial de Protección de Datos está disponible en DataProtectionOffice@flavysofts.com.",
      privacy_date: "Última Actualización: ",

      // buttons
      contact_button: "Contáctenos",
      view_button: "Ver demostración",
      return_button: "Regresar a la página principal",
      fullscreen_button: "Pantalla Completa",
    },
  },
  pt: {
    translation: {
      // Thumbnail
      thumbnail_title: "Sobre NÓS",
      thumbnail_subtitle1:
        "A empresa Flavy é um parceiro confiável no mundo dos jogos de cassino rápidos! Especializamo-nos no desenvolvimento e fornecimento de soluções únicas para jogos de azar, incluindo jogos populares como Crash, Torres, Minas, Plinko e Dados. Nosso objetivo é tornar o seu cassino ainda mais emocionante e lucrativo.",
      thumbnail_subtitle2:
        "O que nos torna especiais? Fornecemos não apenas serviços, mas também uma parceria de longo prazo, incluindo suporte 24 horas, termos flexíveis e preços acessíveis. Nossa equipe é composta por verdadeiros profissionais, cheios de energia e prontos para ajudá-lo a ter sucesso no mundo do entretenimento de azar.",

      // StatsNumbers
      statsnumbers1_title: "Inovação é o nosso padrão",
      statsnumbers1_subtitle:
        "Na Flavy, inovações não são apenas palavras. Estamos sempre buscando novas maneiras de aprimorar nossas soluções, para que seu cassino esteja sempre à frente da concorrência.",
      statsnumbers2_title: "Suporte 24/7",
      statsnumbers2_subtitle:
        "Nossos especialistas estão disponíveis para você 24 horas por dia. Estamos prontos para resolver qualquer problema e responder às suas perguntas a qualquer momento do dia e da noite.",

      statsnumbersgames1_title: "Disponibilidade em Múltiplos Canais",
      statsnumbersgames1_subtitle:
        "O jogo está disponível em várias plataformas: versão web, dispositivos móveis e tablets.",
      statsnumbersgames3_title: "Jogabilidade Empolgante",
      statsnumbersgames3_subtitle:
        "Nossos desenvolvedores cuidaram de cada detalhe para proporcionar uma jogabilidade extremamente envolvente. A experiência do jogador será repleta de emoção e da atmosfera de cassinos.",

      statsnumberscrash2_title: "Saque Automático",
      statsnumberscrash2_subtitle:
        "Oferecemos aos clientes a capacidade de definir seus próprios multiplicadores para saque de fundos.",
      statsnumberscrash4_title: "Segurança e Transparência",
      statsnumberscrash4_subtitle:
        "Valorizamos a segurança e fazemos o possível para proteger os Dice. Nosso jogo Crash utiliza tecnologias avançadas de criptografia e segurança.",

      statsnumbersmines2_title: "Controle de Riscos",
      statsnumbersmines2_subtitle:
        "Mines oferece ao jogador a opção de escolher o nível de dificuldade. Quanto maior a dificuldade, maior o risco.",
      statsnumbersmines4_title: "Segurança e Transparência",
      statsnumbersmines4_subtitle:
        "Valorizamos a segurança e fazemos o possível para proteger os Dice. Nosso jogo Mines utiliza tecnologias avançadas de criptografia e segurança.",

      statsnumberstowers2_title: "Controle de Riscos",
      statsnumberstowers2_subtitle:
        "Towers oferece ao jogador a opção de escolher o nível de dificuldade. Quanto maior a dificuldade, maior o risco.",
      statsnumberstowers4_title: "Segurança e Transparência",
      statsnumberstowers4_subtitle:
        "Valorizamos a segurança e fazemos o possível para proteger os Dice. Nosso jogo Towers utiliza tecnologias avançadas de criptografia e segurança.",

      statsnumbersplinko2_title: "Controle de Riscos",
      statsnumbersplinko2_subtitle:
        "No Plinko, os jogadores têm a opção de escolher a quantidade de compartimentos para apostar, o que adiciona variedade ao jogo. Mais compartimentos significam mais chances de ganhar grandes prêmios, mas também mais riscos.",
      statsnumbersplinko4_title: "Segurança e Transparência",
      statsnumbersplinko4_subtitle:
        "Valorizamos a segurança e fazemos o possível para proteger os Dice. Nosso jogo Plinko utiliza tecnologias avançadas de criptografia e segurança.",

      statsnumbersdice2_title: "Controle de Riscos",
      statsnumbersdice2_subtitle:
        "Dice oferece uma variedade de apostas e oportunidades. O jogador pode escolher diferentes estratégias e níveis de risco.",
      statsnumbersdice4_title: "Segurança e Transparência",
      statsnumbersdice4_subtitle:
        "Valorizamos a segurança e fazemos o possível para proteger os Dice. Nosso jogo Dice utiliza tecnologias avan",

      // Stats
      stats_slider1_title: "Jogos rápidos disponíveis no momento",
      stats_slider2_title: "Aleatoriedade do Resultado:",
      stats_slider2_subtitle:
        "Damos especial atenção à aleatoriedade garantida em nossos jogos. Para isso, utilizamos tecnologias avançadas de geração de números aleatórios.",
      stats_slider3_title: "Nos nossos jogos rápidos estão disponíveis:",
      stats_slider3_subtitle_left: `RTP(taxa de pagamento aos jogadores) -
      Ganhos máximos -
      Apostas mínimas/máximas ($)`,
      stats_slider3_subtitle_right: `até 97% -
      Configurable -
      Configurable`,

      //DemoThumbnail
      demothumbnail_title: "Experimente agora mesmo",
      demothumbnail_mines_subtitle:
        "Mines - Este é um jogo em que a tarefa do jogador é desenterrar o maior número possível de gemas preciosas seguidamente, evitando explosões inesperadas. A cada escolha correta, quando ele encontra uma joia, seus ganhos aumentam, mas à medida que o multiplicador cresce, também aumentam os riscos de encontrar uma mina. O jogo oferece a ele uma escolha: jogar com segurança e pegar os ganhos ou arriscar e ir atrás do grande prêmio.",
      demothumbnail_plinko_subtitle:
        "Plinko - Este é um jogo de azar envolvente e rápido, onde os ganhos são determinados pelo local onde a bola deslizante cai.",
      demothumbnail_towers_subtitle:
        "Towers - Este é um modo empolgante, onde o jogador deve fazer escolhas certas, evitando bombas, para avançar para o próximo nível. A cada nível bem-sucedido, seus ganhos aumentam. Em Towers, cada movimento pode resultar em lucro ou perda para o jogador.",
      demothumbnail_dice_subtitle:
        "Dice - Este é um jogo de azar e estratégia em que o jogador lança o dado e aposta no resultado. Quanto maior o risco, maior a recompensa. O jogador define sua estratégia, experimentando a emoção.",
      demothumbnail_crash_subtitle:
        "Crash - Este é um jogo baseado em apostas em um multiplicador que cresce rapidamente. A tarefa do jogador é sair do jogo a tempo antes que ele 'caia'. Quanto mais tempo o jogador espera, maior pode ser o prêmio. No entanto, à medida que o multiplicador aumenta, também aumenta o risco de perder a aposta.",

      //ContactForm
      contactform_title: " Contate-nos",
      contactform_name: "Name*",
      contactform_company: "Company*",
      contactform_email: "E-mail address*",
      contactform_message: "Your Message*",

      //Header
      header_home: "Página Principal",
      header_products: "Produtos",
      header_contact: " Contate-nos",
      header_language: "Língua:",

      //Privacy Policy
      privacy_title: "POLÍTICA DE PRIVACIDADE",
      privacy_subtitle:
        "Bem-vindo ao Flavy Softs, o seu destino para experiências digitais de última geração, operado pela FLAVY LLC. Na FLAVY SOFTS, priorizamos a sua privacidade, e esta Política de Privacidade otimizada para SEO destaca como coletamos, utilizamos e protegemos as suas informações quando você interage com os nossos serviços no site flavysofts.com.",
      privacy_item1_title: "1. Coleta de Dados:",
      privacy_item1_subtitle:
        "Descubra uma experiência de usuário sem interrupções ao nos fornecer certas informações pessoalmente identificáveis, como seu nome, endereço, e-mail, número de telefone e credenciais de conta. Além disso, aprimoramos a sua interação ao reunir detalhes técnicos, registros de comunicações na Internet e dados de localização.",
      privacy_item2_title: "2. Base Legal para o Processamento:",
      privacy_item2_subtitle:
        "Tenha a certeza de que processamos dados pessoais em estrita conformidade com o seu consentimento explícito ou quando obtidos de fontes publicamente disponíveis, aderindo a padrões legais.",
      privacy_item3_title: "3. Confidencialidade:",
      privacy_item3_subtitle:
        "Os seus dados pessoais são tratados como confidenciais, protegidos por medidas legais robustas. Nossa equipe comprometida, incluindo funcionários e pessoal autorizado, cumpre acordos de não divulgação e compreende a gravidade de violar as normas de processamento de dados.",
      privacy_item4_title: "4. Uso de Dados:",
      privacy_item4_subtitle:
        "Mergulhe nos nossos serviços à medida que processamos os seus dados para fornecer e manter experiências de alta qualidade, cumprir obrigações legais, processar transações, oferecer suporte ao cliente responsivo, aprimorar serviços e solucionar problemas técnicos.",
      privacy_item5_title: "5. Compartilhamento dos Seus Dados:",
      privacy_item5_subtitle:
        "Experimente jogos sem problemas por meio de fornecedores confiáveis de terceiros que oferecem serviços essenciais. Isso inclui fornecedores de software e jogos, serviços bancários e de pagamento, verificação de identidade, prevenção de fraudes e gestão de riscos. Tenha a certeza de que, em conformidade com obrigações legais, as informações podem ser divulgadas às autoridades governamentais.",
      privacy_item6_title: "6. Armazenamento e Proteção de Dados:",
      privacy_item6_subtitle:
        "Os seus dados são armazenados com segurança e protegidos por controles contratuais rigorosos e medidas de criptografia. Sinta-se confiante de que retemos os seus dados enquanto você for um cliente ativo e conforme exigido por obrigações legais.",
      privacy_item7_title: "7. Seus Direitos:",
      privacy_item7_subtitle:
        "Abraçe os seus direitos fundamentais como sujeito de dados - retirar o consentimento, acessar e corrigir os seus dados, solicitar a exclusão, restringir o processamento e desfrutar do direito de se opor ao processamento.",
      privacy_item8_title: "8. Política de Cookies:",
      privacy_item8_subtitle:
        "Desfrute de uma experiência online personalizada, pois utilizamos cookies para aumentar a satisfação do usuário, otimizar a funcionalidade do site e coletar dados para prevenção de fraudes. Gerencie as suas preferências ajustando as configurações do navegador, lembrando que bloquear cookies pode afetar a funcionalidade do site.",
      privacy_item9_title: "9. Alterações a esta Política:",
      privacy_item9_subtitle:
        "Mantenha-se atualizado sobre a evolução da sua jornada digital. Reservamos o direito de aprimorar esta Política de Privacidade, com alterações substanciais comunicadas diretamente. O seu uso contínuo dos nossos serviços após as atualizações implica a aceitação da política revisada.",
      privacy_footer:
        "Para dúvidas sobre os seus dados pessoais, o nosso Oficial de Proteção de Dados está disponível em DataProtectionOffice@flavysofts.com.",
      privacy_date: "Última Atualização: ",

      // buttons
      contact_button: " Contate-nos",
      view_button: "Ver demonstração",
      return_button: "Retornar a página inicial",
      fullscreen_button: "Tela Cheia",
    },
  },
};

i18n

  // to detect user language

  .use(LanguageDetector)

  // to pass the i18n instance to react-i18next.

  .use(initReactI18next)

  // init i18next

  .init({
    fallbackLng: "en",
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
