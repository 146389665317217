import { Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import Loading from "../components/UI/Loading/Loading";

const MainPage = lazy(() => import("../pages/MainPage"));
const PrivacyPage = lazy(() => import("../pages/PrivacyPage"));
const PlinkoPage = lazy(() => import("../pages/PlinkoPage"));
const TowersPage = lazy(() => import("../pages/TowersPage"));
const MinesPage = lazy(() => import("../pages/MinesPage"));
const DicePage = lazy(() => import("../pages/DicePage"));
const CrashPage = lazy(() => import("../pages/CrashPage"));

export const useRoutes = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw"
          }}
        >
          <Loading>Loading</Loading>
        </div>
      }
    >
      <Routes>
        <Route path="/crash" element={<CrashPage />} />
        <Route path="/plinko" element={<PlinkoPage />} />
        <Route path="/mines" element={<MinesPage />} />
        <Route path="/dice" element={<DicePage />} />
        <Route path="/towers" element={<TowersPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="/" element={<MainPage />} />
      </Routes>
    </Suspense>
  );
};
